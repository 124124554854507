.topo{
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding: 15px;
    color: #ffffff;
}
.ilimitmenu{
    display: flex;
}

.ilimitmenu  a{
    font-size: 15px;
    font-weight: bold;
    margin-left: 8px;
    margin-right: 8px;
    cursor: pointer;
    color: #fff;
}
.linkmenu{
    font-size: 15px;
    font-weight: bold;
    margin-left: 8px;
    margin-right: 8px;
    cursor: pointer;
    color: #fff;
}
.linkmenu:hover{
    color: #003A5B;
    text-decoration:none
}
.ilimitmenu a:hover{
    color: #003A5B;
    border-bottom: 3px solid #003A5B;
    text-decoration:none
}
.boxContador{
    background-color: #04202E;
    height: 150px;
    width: 60%;
    margin-top: -80px;
    position: relative;
    z-index: 99999;
    border-top: 5px solid #fff;
}
.blocos{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    color: #fff;
    height: 100%;
}
.blocounico{
    height: 70%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 100%;
}
.titulo1sobre{
    font-size: 30px;
    font-weight: bold;
    color: #fff;
}
.titulo2sobre{
    font-size: 30px;
    font-weight: bold;
    color: #003A5B;
}
.textosobre{
    color: #fff;
    margin-top: 15px;
    font-size: 12px;
    font-weight: 400;
}
.tituloseguro{
    color: #fff;
    font-size: 35px;
    font-weight: bold;
    text-align: center;
}
.subtituloseguro{
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    margin-top: 15px;
}
.cartao{
    height: 280px;
    width: 230px;
    background-color: #00A2FF;
    border-radius: 15px;
    border:1px solid #fff;
    margin: 10px;
    padding: 25px;
}
.separador{
    border-top: 2px solid #fff;
    width: 90%;
    margin: auto;
}

.btnbannermoto{
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: rgb(0, 89, 140);
    padding: 15px;
    height: 50px;
    margin-top: -70px;
    margin-right: 50px;
    border-radius: 15px;
    color: #fff;
    cursor: pointer;
    font-weight: 300;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.titulocontato{
    color: #fff;
    font-weight: bold;
    font-size: 35px;
}
.subtitulocontato{
    color: #fff;
    font-size: 12px;
}
.estiloinput{
    color:#C0C0C0 !important;
    margin-top: 15px;
    border: 2px solid #fff;
    font-weight: 500;
}
.estiloinput:focus{
    background-color: #04202e;
    color:#fff !important;
    margin-top: 15px;
    border: 2px solid #fff;
    font-weight: 500;
}
.celular{
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #C0C0C0 !important;
    background-clip: padding-box;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.btn_envia{
    margin-top: 15px;
    width: 50%;
    background-color: #00598C  !important;
    border-color: #00598C !important;
    border-radius: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.cardbranco{
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    height: 180px;
    margin-top: 10px;

}
.brands{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.inputfeed{
    background-color: #3C3C3C;
    color:#fff !important;
    border: 0;
}

.cardTotal{
    display: flex;
    align-content: center;
    justify-content: center;
}
.cardPlanoesq{
    border-radius: 10px;
    background-color: #0286b9;
    border: 1px solid #154a5e;
    padding: 20px;
    color: #fff;
    z-index: -9999;
    margin-right: -35px;
    padding-right: 40px;
    -webkit-box-shadow: 0px 0px 15px 2px #000000;
    box-shadow: 0px 0px 15px 2px #000000;
}
.cardPlanomeio{
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #154a5e;
    padding: 30px;
    color: #04202e;
    z-index: 99999;
    margin-bottom: 16px;
    margin-top: -16px;
    -webkit-box-shadow: 0px 0px 15px 2px #000000;
    box-shadow: 0px 0px 15px 2px #000000;
    
}
.cardPlanodir{
    border-radius: 10px;
    background-color: #0286b9;
    border: 1px solid #154a5e;
    padding: 20px;
    color: #fff;
    z-index: -9999;
    margin-left: -35px;
    padding-left: 40px;
    -webkit-box-shadow: 0px 0px 15px 2px #000000;
    box-shadow: 0px 0px 15px 2px #000000;
}

@media only screen and (max-width: 600px){
    .cardPlanoesq {
        margin-right: 0px;
        padding-right: 0px;
    }
    .cardPlanodir{
        margin-left: 0px;
        padding-left: 20px;
    }
    .cardPlanomeio{
        margin-top: 16px;
    }
    .cardTotal{
        display: block;
    }
    .logoquemsomosmobile{
        width: 200px !important;
    }
    .escodeMobile{
        display:none;
    }
    .quemsomoscontainermobile{
        margin-top: 10px !important;
    }
    .boxContador{
        width:100%;
        z-index: 9;
    }
    
    .blocounico > div > span{
        font-size: 14px !important;
        
    }
    .blocounico > div{
        padding: 15px;
    }
    .boxContador {
        margin-top: -5px;
        height: 100px;
    }
    .btnbannermoto{
        margin-top: 10px;
        margin-right: 20%;
    }
    .brands{
        width: 180px;
        margin:auto
    }
    .footermobile{
        padding: 50px !important;
    }
    .menuMobile{
        padding: 25px;
        display: flex;
        justify-content: space-between;
        color: #fff;
        font-size: 35px;
        position: fixed;
        width: 100%;
        z-index: 99999;
        background-color: #04202e;
        
    }
    .bannersmobile{
        margin-top: 93px !important;
    }
}